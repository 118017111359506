import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiUrl } from '../../constants/apiUrl.const';

@Injectable({
  providedIn: 'root'
})
export class OpinionService {
  private readonly httpClient = inject(HttpClient);

  public sendOpinion(hash: string, stars: number, opinion: string) {
    return this.httpClient.post(`${ApiUrl}/feedback/${hash}`, { stars, feedback: opinion });
  }
}
