<div class="flex flex-col">
  <div class="w-full flex flex-col items-center">
    <ng-container *ngIf="!sent() else Sent">
      <h1 class="font-bold text-2xl text-center" i18n>Powiedz nam co możemy poprawić!</h1>
    </ng-container>

    <ng-template #Sent>
      <h1 class="font-bold text-2xl" i18n>Wiadomość Wysłana</h1>
      <p class="text-black font-light mb-6 text-sm" i18n>Dziękujemy!</p>
    </ng-template>
  </div>

  <form *ngIf="!sent()" [formGroup]="form" class="w-full">
    <div class="w-full mb-5">
      <app-label for="email" i18n-label label="Email"></app-label>
      <input
        formControlName="email"
        type="text"
        name="email"
        id="email"
        class="appearance-none text-sm rounded-[12px] border-gray-200 focus:border-black focus:ring-0 focus:outline-none cursor-text transition-colors duration-100 ease-in-out w-full"
        placeholder=" "
        [class.border-red-500]="form.get('email')?.invalid && form.get('email')?.touched && submitted()"
      />
      <p
        *ngIf="form.get('email')?.invalid && form.get('email')?.touched && submitted()"
        class="text-red-500 text-sm mt-1"
        i18n
      >Niepoprawny email</p>
    </div>

    <div class="w-full">
      <app-label for="opinion" i18n-label label="Wiadomość"></app-label>
      <textarea
        formControlName="opinion"
        rows="5"
        type="text"
        name="opinion"
        id="opinion"
        class="mb-5 appearance-none text-sm rounded-[12px] border-gray-200 focus:border-black focus:ring-0 focus:outline-none cursor-text transition-colors duration-100 ease-in-out w-full"
        placeholder=" "
      ></textarea>
    </div>
  </form>
</div>


<button btn-primary btnSize="large" class="w-full" (click)="leaveAReviewClicked()">
  <ng-container *ngIf="!loading() else Loader" i18n>Wyślij</ng-container>
  <ng-template #Loader>
    <loader [size]="'w-6 h-6'"></loader>
  </ng-template>
</button>
