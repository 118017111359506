import { ChangeDetectionStrategy, Component, computed, input, model } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'stars',
  imports: [CommonModule],
  host: {
    class: 'flex items-center'
  },
  template: `
    <span
      *ngFor="let star of stars(); let i = index"
      class="material-symbols-outlined text-yellow-300"
      [style.font-variation-settings]="getFill(star)"
      [style.font-size]="'inherit'"
      [class.pointer-events-none]="disabled()"
      (click)="rating.set(i + 1)"
    >star</span>
  `,
})
export class StarsComponent {
  rating = model<number>(0);
  disabled = input<boolean>(false);
  stars = computed(() => Array(5).fill(false).map((_, i) => i < this.rating()));

  getFill(star: boolean): string {
    return star ? '\'FILL\' 0.5' : '\'FILL\' 0.3';
  }
}
