@if (questionsService.redirect()) {
  <div *ngIf="!sent()" class="mb-7 flex flex-col items-center">
    <div *ngIf="questionsService.redirect()?.starsEnabled" class="flex items-center justify-center relative">
      <stars
        class="absolute top-0 -mt-1"
        [@stars]="ratedNegatively() ? 'back' : 'front'"
        [(rating)]="questionsService.rating"
        [disabled]="ratedNegatively()"
      ></stars>
    </div>
  </div>

  <h1
    [@text]="ratedNegatively() ? 'back' : 'front'"
    class="font-bold text-2xl mb-3"
    i18n
  >Oceń nas!</h1>

  <email-review-form
    [@moveIn]="ratedNegatively() && questionsService.rating() < 4 ? 'front' : 'back'"
    [@appear]="ratedNegatively() && questionsService.rating() < 4 ? 'front' : 'back'"
    [(sent)]="sent"
    class="flex flex-col items-center mb-4 w-full"
  ></email-review-form>
}
