import { ChangeDetectionStrategy, Component, inject, OnInit, ViewContainerRef } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { initFlowbite } from 'flowbite';
import { SvgIconComponent } from './ui/svg/svg.component';
import { InlineSvgsComponent } from './ui/svg/inlineSvgs.component';
import { CommonModule } from '@angular/common';
import { ModalService } from './ui/modal/modal.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterModule, CommonModule, SvgIconComponent, InlineSvgsComponent],
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  title = 'taply-frontend';
  private readonly modalService = inject(ModalService);

  constructor(
    private readonly router: Router,
    private readonly viewContainerRef: ViewContainerRef
  ) {
    this.router.events.subscribe(() => {
      initFlowbite();
    });

    this.modalService.viewContainerRef = this.viewContainerRef;
  }

  ngOnInit(): void {
    initFlowbite();
  }
}
