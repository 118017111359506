import { Question } from './question.type';
import { GrammaticalForm } from './grammaticalForm.enum';
import { Language } from './language.enum';

export interface Redirect {
  clientId: number;
  name: string;
  reviewUrl: string;
  logoUrl?: string;
  questions: Question[];
  questionsEn: Question[];
  reviewAssistantEnabled: boolean;
  starsEnabled: boolean;
}
export const Redirect = {
  localize: (redirect: Redirect, language: Language): Redirect => {
    return {
      ...redirect,
      questions: language === Language.Pl
        ? [...redirect.questions, GrammaticalFormQuestion]
        : redirect.questionsEn?.length ? redirect.questionsEn: redirect.questions
    };
  }
};

const GrammaticalFormQuestion: Question = {
  question: $localize`W jakiej formie napisać opinię?`,
  questionId: 'gender',
  questionType: 'singleAnswer' as const,
  possibleAnswers: [
    { answerId: GrammaticalForm.Feminine, answer: $localize`Żeńskiej` },
    { answerId: GrammaticalForm.Masculine, answer: $localize`Męskiej` },
    { answerId: GrammaticalForm.Impersonal, answer: $localize`Generuj opinie w formie bezosobowej` }
  ]
};
