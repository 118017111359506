import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, Routes } from '@angular/router';
import { RedirectContainerComponent } from './redirect/components/redirect/redirectContainer/redirectContainer.component';
import { ReviewViewComponent } from './redirect/components/googleReview/reviewView/reviewView.component';
import { inject } from '@angular/core';
import { QuestionsService } from './redirect/services/questions.service';
import { NotFoundComponent } from './redirect/components/notFound/notFound.component';
import { EmailReviewViewComponent } from './redirect/components/emailReview/emailReviewView/emailReviewView.component';
import { RedirectViewComponent } from './redirect/components/redirect/redirectView/redirectView.component';

export const routes: Routes = [
  {
    path: 'redirect',
    children: [
      {
        path: ':hash',
        component: RedirectContainerComponent,
        children: [
          {
            path: '',
            component: RedirectViewComponent
          },
          {
            path: 'review',
            canActivate: [
              (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
                const questionsService = inject(QuestionsService);
                const router = inject(Router);
                const hash = route.parent?.params['hash'];

                if (!questionsService.redirect() || !questionsService.redirect()?.reviewAssistantEnabled) {
                  return router.createUrlTree(['/redirect', hash]);
                }
                return true;
              }
            ],
            component: ReviewViewComponent
          },
          {
            path: 'opinion',
            canActivate: [
              (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
                const questionsService = inject(QuestionsService);
                const router = inject(Router);
                const hash = route.parent?.params['hash'];


                if (!questionsService.redirect()?.starsEnabled) {
                  return router.createUrlTree(['/redirect', hash]);
                }
                return true;
              }
            ],
            component: EmailReviewViewComponent
          }
        ]
      }
    ]
  },
  {
    path: 'googleOAuth',
    loadComponent: () => import('./onboarding/components/googleAuth/googleRedirect.component').then(m => m.GoogleRedirectComponent)
  },
  {
    path: 'checkout',
    loadComponent: () => import('./checkout/checkout/checkout.component').then(m => m.CheckoutComponent)
  },
  {
    path: 'checkoutReturn',
    loadComponent: () => import('./checkout/checkoutReturn/checkoutReturn.component').then(m => m.CheckoutReturnComponent)
  },
  {
    path: 'checkoutSuccess/:checkoutSessionReferenceId',
    loadComponent: () => import('./checkout/checkoutSuccess/checkoutSuccess.component').then(m => m.CheckoutSuccessComponent)
  },
  {
    path: 'admin/login',
    loadComponent: () => import('./auth/adminLogin/adminLogin.component').then(m => m.AdminLoginComponent)
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.routes').then(m => m.AdminRoutes)
  },
  {
    path: 'client',
    loadChildren: () => import('./client/client.routes').then(m => m.ClientRoutes)
  },
  {
    path: 'login',
    loadComponent: () => import('./auth/login/login.component').then(m => m.LoginComponent),
  },
  {
    path: 'register',
    loadComponent: () => import('./auth/register/register.component').then(m => m.RegisterComponent)
  },
  {
    path: 'loginEmailSent',
    loadComponent: () => import('./auth/emailLogin/loginEmailSent.component').then(m => m.LoginEmailSentComponent)
  },
  {
    path: 'emailLogin/:token',
    loadComponent: () => import('./auth/emailLogin/emailLoginRedirect.component').then(m => m.EmailLoginRedirectComponent)
  },
  {
    path: 'activate',
    loadChildren: () => import('./onboarding/onboardingRoutes').then(m => m.OnboardingRoutes)
  },
  {
    path: '404',
    component: NotFoundComponent,
    canActivate: [
      () => {
        const router = inject(Router);
        if (sessionStorage.getItem('paymentRedirectUrl') && document.referrer.includes('payu.com')) {
          sessionStorage.removeItem('paymentRedirectUrl');
          return router.createUrlTree(['/client/subscription']);
        }

        return router.createUrlTree(['/client']);
      }
    ]
  },
  {
    path: '**',
    redirectTo: '404'
  }
];
