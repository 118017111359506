<div *ngIf="!reviews?.length; else Reviews" class="mb-4">
  <button
    class="outline outline-gray-200 rounded-[12px] min-h-[152px] max-h-[152px] w-full cursor-pointer transition-colors duration-100 ease-in-out text-sm text-left flex justify-center items-center"
  >
    <loader></loader>
  </button>
</div>

<ng-template #Reviews>
  <div *ngFor="let review of reviews; let i = index" class="relative mb-4">

    <button
      (click)="copy(i, review)"
      class="outline rounded-[12px] py-4 px-6 cursor-pointer transition-colors duration-100 ease-in-out text-sm text-left flex justify-between items-center w-full"
      [ngClass]="{
      'outline-offset-2 outline-black': copied() === review,
      'outline-offset-1 outline-gray-200': copied() !== review
    }"
    >
      <span>{{ review }}</span>
      <!-- Simplified Copy SVG Icon -->
      <svg class="min-h-[24px] h-[24px] min-w-[24px] w-[24px] ml-4" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000" version="1.1" viewBox="0 0 460 460" xml:space="preserve">
      <g>
        <path d="M425.934,0H171.662c-18.122,0-32.864,14.743-32.864,32.864v77.134h30V32.864c0-1.579,1.285-2.864,2.864-2.864h254.272     c1.579,0,2.864,1.285,2.864,2.864v254.272c0,1.58-1.285,2.865-2.864,2.865h-74.729v30h74.729     c18.121,0,32.864-14.743,32.864-32.865V32.864C458.797,14.743,444.055,0,425.934,0z"/>
        <path d="M288.339,139.998H34.068c-18.122,0-32.865,14.743-32.865,32.865v254.272C1.204,445.257,15.946,460,34.068,460h254.272     c18.122,0,32.865-14.743,32.865-32.864V172.863C321.206,154.741,306.461,139.998,288.339,139.998z M288.341,430H34.068     c-1.58,0-2.865-1.285-2.865-2.864V172.863c0-1.58,1.285-2.865,2.865-2.865h254.272c1.58,0,2.865,1.285,2.865,2.865v254.273h0.001     C291.206,428.715,289.92,430,288.341,430z"/>
      </g>
    </svg>
    </button>

    <span
      *ngIf="copied() === review"
      class="absolute -top-3 right-2 text-sm text-black flex items-center gap-2 z-20 bg-white px-3 subtle-bouncy-scale-up"
      i18n
    >
    Skopiowano
    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
      <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
    </svg>
  </span>
  </div>

</ng-template>
