import { inject } from '@angular/core';
import { HttpRequest, HttpEvent, HttpErrorResponse, HttpHandlerFn } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { ApiUrl } from '../constants/apiUrl.const';

export function authInterceptorFunction(request: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> {
  const authService = inject(AuthService);
  const authToken = authService.getAuthorizationToken();

  if (authToken && !request.headers.has('Authorization')) {
  request = request.clone({
    setHeaders: {
      Authorization: authToken
    }
  });
}

return next(request).pipe(
  catchError((error: HttpErrorResponse) => {
    if (
      (error.status === 401 || error.status === 403 || error.status === 400)
      && request.url.match(new RegExp(`${ApiUrl}/(client)|(redirectConfig)`))
      && !request.url.match(new RegExp(`${ApiUrl}/client/redirect`))
    ) {
      authService.adminLogout();
    }

    if (error.status === 401) {
      authService.logout();
    }

    // Propagate the error
    return throwError(() => error);
  })
);
}
