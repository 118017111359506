import { inject, Injectable, signal } from '@angular/core';
import { MixpanelService } from '../../mixpanel/mixpanel.service';
import { Redirect } from '../types/redirect.type';

@Injectable()
export class RedirectMixpanelService {
  public redirect = signal<Redirect & { hash: string } | undefined>(undefined);
  private readonly mixpanelService = inject(MixpanelService);

  public track(event: string, properties: any): void {
    this.mixpanelService.track(
      event,
      {
        ...properties,
        redirectHash: this.redirect()?.hash,
        clientId: this.redirect()?.clientId,
        clientName: this.redirect()?.name,
        clientStarsEnabled: this.redirect()?.starsEnabled,
        clientReviewAssistantEnable: this.redirect()?.reviewAssistantEnabled
      }
    );
  }
}
