<div class="hide">
  <svg xmlns="http://www.w3.org/2000/svg">
    <symbol id="sign-black" viewBox="0 0 143.8 146.7">
      <g>
        <circle cx="97.5" cy="101.4" r="17.2"/>
        <circle cx="44.5" cy="101.4" r="17.2"/>
        <g>
          <path d="M116.5,47.2c0,10.5-8.5,19-19,19c-10.5,0-19-8.5-19-19s8.5-19,19-19C108,28.2,116.5,36.7,116.5,47.2z M82.2,47.2
			c0,8.5,6.9,15.3,15.3,15.3s15.3-6.9,15.3-15.3s-6.9-15.3-15.3-15.3S82.2,38.8,82.2,47.2z"/>
        </g>
        <circle cx="44.5" cy="47.2" r="17.2"/>
      </g>
    </symbol>

    <symbol id="menu" stroke-width="1.5px" viewBox="0 0 24 24" stroke="currentColor" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path stroke-linecap="round" d="M3 6H21M3 12H11M3 18H16"></path>
    </symbol>

    <symbol id="sign-white" viewBox="0 0 143.8 146.7">
      <g>
        <circle class="st0" cx="97.5" cy="101.4" r="17.2"/>
        <circle class="st0" cx="44.5" cy="101.4" r="17.2"/>
        <g>
          <path class="st0" d="M116.5,47.2c0,10.5-8.5,19-19,19c-10.5,0-19-8.5-19-19s8.5-19,19-19C108,28.2,116.5,36.7,116.5,47.2z
             M82.2,47.2c0,8.5,6.9,15.3,15.3,15.3s15.3-6.9,15.3-15.3s-6.9-15.3-15.3-15.3S82.2,38.8,82.2,47.2z"/>
        </g>
        <circle class="st0" cx="44.5" cy="47.2" r="17.2"/>
      </g>
    </symbol>

    <symbol id="logo-black" viewBox="0 0 480 197">
      <g>
        <g>
          <path class="st0" d="M125.1,67.6h10.6V51.5h18.5v16.1h15.6v16.1h-15.6v23.7c0,3.1,1,5.4,2.9,7.2c2,1.7,4.4,2.6,7.4,2.6
            c2.2,0,4.5-0.5,6.9-1.5v15.2c-4.1,1.6-8.1,2.4-12,2.4c-7.6,0-13.5-2.1-17.6-6.2c-4.1-4.1-6.2-10-6.2-17.6V83.7h-10.6V67.6z"/>
          <path class="st0" d="M182.3,114.3c0-4.3,1.2-8.1,3.7-11.5c2.5-3.4,5.9-6.1,10.2-8c4.3-2,9.3-3,14.9-3c1.8,0,3.6,0.1,5.6,0.3
            c2,0.2,3.9,0.5,5.9,0.8c-1.4-7-6.5-10.5-15.3-10.5c-5.2,0-10.4,2-15.6,5.9l-6.4-15.7c4.5-2.1,8.9-3.7,13.2-4.8
            c4.3-1,8.5-1.5,12.4-1.5c6.2,0,11.6,1.2,16.2,3.5c4.6,2.3,8.1,5.6,10.7,9.9c2.5,4.3,3.8,9.3,3.8,15V132h-15.6l-1.2-6.9
            c-2.4,2.5-5.3,4.5-8.8,6c-3.5,1.5-7.2,2.2-11.4,2.2c-6.5,0-11.9-1.8-16-5.3C184.4,124.5,182.3,119.9,182.3,114.3z M200.9,110
            c0,2.1,0.8,3.9,2.5,5.2c1.7,1.3,3.8,2,6.5,2c3.7,0,6.8-1.1,9.3-3.3c2.5-2.2,3.8-5,3.8-8.2v-1.3c-1.6-0.5-3.3-0.9-5.1-1.2
            c-1.8-0.3-3.6-0.4-5.4-0.4c-3.6,0-6.5,0.6-8.5,1.9C201.9,105.9,200.9,107.7,200.9,110z"/>
          <path class="st0" d="M257,67.6h17.5l0.5,7c2.7-2.6,5.9-4.6,9.5-6.1c3.6-1.4,7.5-2.2,11.7-2.2c6.1,0,11.4,1.4,16.2,4.3
            c4.7,2.9,8.4,6.9,11.1,11.9c2.7,5,4.1,10.8,4.1,17.2c0,6.4-1.4,12.2-4.1,17.2s-6.4,9-11.1,11.9c-4.7,2.9-10.1,4.3-16.2,4.3
            c-4,0-7.8-0.7-11.3-2c-3.5-1.4-6.6-3.2-9.3-5.6v38.6H257V67.6z M291.9,82.4c-4.7,0-8.6,1.7-11.7,5c-3.1,3.3-4.6,7.4-4.6,12.4
            c0,5,1.5,9.1,4.6,12.4c3.1,3.3,7,5,11.7,5c4.7,0,8.6-1.7,11.7-5c3.1-3.3,4.6-7.5,4.6-12.4c0-5-1.5-9.1-4.6-12.4
            C300.5,84.1,296.6,82.4,291.9,82.4z"/>
          <path class="st0" d="M358.2,32.8V132h-18.5V32.8H358.2z"/>
          <path class="st0" d="M387.8,67.6l15.1,31.5c0.7,1.4,1.3,2.9,1.9,4.7c0.6,1.8,1.1,3.7,1.5,5.8l17.4-42h19.6l-40.1,96.6h-19.5
            l14.7-35.4l-30-61.2H387.8z"/>
        </g>
        <g>
          <circle class="st0" cx="83.6" cy="112.2" r="11.5"/>
          <circle class="st0" cx="48.1" cy="112.2" r="11.5"/>
          <g>
            <path class="st0" d="M96.4,76c0,7-5.7,12.7-12.7,12.7c-7,0-12.7-5.7-12.7-12.7s5.7-12.7,12.7-12.7C90.6,63.3,96.4,69,96.4,76z
               M73.4,76c0,5.7,4.6,10.3,10.3,10.3S93.9,81.7,93.9,76s-4.6-10.3-10.3-10.3S73.4,70.3,73.4,76z"/>
          </g>
          <circle class="st0" cx="48.1" cy="76" r="11.5"/>
        </g>
      </g>
    </symbol>

    <symbol id="check" viewBox="0 0 24 24">
      <path d="M4 12.6111L8.92308 17.5L20 6.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </symbol>

    <symbol id="google" viewBox="0 0 24 24">
      <path d="M19.76 10.77L19.67 10.42H12.23V13.58H16.68C16.4317 14.5443 15.8672 15.3974 15.0767 16.0029C14.2863 16.6084 13.3156 16.9313 12.32 16.92C11.0208 16.9093 9.77254 16.4135 8.81999 15.53C8.35174 15.0685 7.97912 14.5191 7.72344 13.9134C7.46777 13.3077 7.33407 12.6575 7.33 12C7.34511 10.6795 7.86792 9.41544 8.79 8.47002C9.7291 7.58038 10.9764 7.08932 12.27 7.10002C13.3779 7.10855 14.4446 7.52101 15.27 8.26002L17.47 6.00002C16.02 4.70638 14.1432 3.9941 12.2 4.00002C11.131 3.99367 10.0713 4.19793 9.08127 4.60115C8.09125 5.00436 7.19034 5.59863 6.43 6.35002C4.98369 7.8523 4.16827 9.85182 4.15152 11.9371C4.13478 14.0224 4.918 16.0347 6.34 17.56C7.12784 18.3449 8.06422 18.965 9.09441 19.3839C10.1246 19.8029 11.2279 20.0123 12.34 20C13.3484 20.0075 14.3479 19.8102 15.2779 19.42C16.2078 19.0298 17.0488 18.4549 17.75 17.73C19.1259 16.2171 19.8702 14.2347 19.83 12.19C19.8408 11.7156 19.8174 11.2411 19.76 10.77Z" fill="currentColor"/>
    </symbol>

    <symbol id="star-fill" viewBox="0 0 24 24">
      <path d="M21.12 9.88005C21.0781 9.74719 20.9996 9.62884 20.8935 9.53862C20.7873 9.4484 20.6579 9.38997 20.52 9.37005L15.1 8.58005L12.67 3.67005C12.6008 3.55403 12.5027 3.45795 12.3853 3.39123C12.2678 3.32451 12.1351 3.28943 12 3.28943C11.8649 3.28943 11.7322 3.32451 11.6147 3.39123C11.4973 3.45795 11.3991 3.55403 11.33 3.67005L8.89999 8.58005L3.47999 9.37005C3.34211 9.38997 3.21266 9.4484 3.10652 9.53862C3.00038 9.62884 2.92186 9.74719 2.87999 9.88005C2.83529 10.0124 2.82846 10.1547 2.86027 10.2907C2.89207 10.4268 2.96124 10.5512 3.05999 10.6501L6.99999 14.4701L6.06999 19.8701C6.04642 20.0091 6.06199 20.1519 6.11497 20.2826C6.16796 20.4133 6.25625 20.5267 6.36999 20.6101C6.48391 20.6912 6.61825 20.7389 6.75785 20.7478C6.89746 20.7566 7.03675 20.7262 7.15999 20.6601L12 18.1101L16.85 20.6601C16.9573 20.7189 17.0776 20.7499 17.2 20.7501C17.3573 20.7482 17.5105 20.6995 17.64 20.6101C17.7537 20.5267 17.842 20.4133 17.895 20.2826C17.948 20.1519 17.9636 20.0091 17.94 19.8701L17 14.4701L20.93 10.6501C21.0305 10.5523 21.1015 10.4283 21.1351 10.2922C21.1687 10.1561 21.1634 10.0133 21.12 9.88005Z" fill="currentColor"/>
    </symbol>

    <symbol id="bell-fill" viewBox="0 0 24 24">
      <path d="m9,17l-3.39,0c-1.26,0 -1.89,0 -2.02,-0.1c-0.15,-0.11 -0.19,-0.17 -0.2,-0.36c-0.02,-0.16 0.37,-0.79 1.14,-2.05c0.79,-1.31 1.47,-3.2 1.47,-5.89c0,-1.49 0.63,-2.91 1.76,-3.96c1.12,-1.05 2.65,-1.64 4.24,-1.64c1.59,0 3.12,0.59 4.24,1.64c1.13,1.05 1.76,2.47 1.76,3.96c0,2.69 0.68,4.58 1.47,5.89c0.77,1.26 1.16,1.89 1.15,2.05c-0.02,0.19 -0.06,0.25 -0.21,0.36c-0.13,0.1 -0.76,0.1 -2.02,0.1l-3.39,0m-6,0l0,1c0,1.66 1.34,3 3,3c1.66,0 3,-1.34 3,-3l0,-1m-6,0l6,0" stroke="currentColor" fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    </symbol>

    <symbol id="briefcase" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
      <rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect><path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
    </symbol>

    <symbol id="home" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
      <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline>
    </symbol>

    <symbol id="credit-card" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
      <rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect><line x1="1" y1="10" x2="23" y2="10"></line>
    </symbol>

    <symbol id="rss" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
      <path d="M4 11a9 9 0 0 1 9 9"></path><path d="M4 4a16 16 0 0 1 16 16"></path><circle cx="5" cy="19" r="1"></circle>
    </symbol>

    <symbol id="star" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
      <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
    </symbol>

    <symbol id="check-circle" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
      <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"/><polyline points="22 4 12 14.01 9 11.01"/>
    </symbol>

    <symbol id="x-circle" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
      <circle cx="12" cy="12" r="10"/><line x1="15" y1="9" x2="9" y2="15"/><line x1="9" y1="9" x2="15" y2="15"/>
    </symbol>

    <symbol id="edit" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
      <path d="M12 20h9"></path><path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
    </symbol>

    <symbol id="chevron-left" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
      <polyline points="15 18 9 12 15 6"/>
    </symbol>

    <symbol id="chevron-right" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
      <polyline points="9 18 15 12 9 6"/>
    </symbol>
  </svg>
</div>
