import { Component, EventEmitter, Output, inject } from '@angular/core';
import { ButtonDirective } from '../../../../ui/button/button.directive';
import { QuestionsService } from '../../../services/questions.service';
import { RedirectMixpanelService } from '../../../services/redirectMixpanel.service';
import { LogoOrTitleComponent } from '../../../../ui/logoOrTitle/logoOrTitle.component';

@Component({
  selector: 'review-or-generate',
  template: `
    <div class="h-full flex flex-col justify-center">

      <button
        btn-primary
        btnSize="large"
        class="w-full mb-5"
        i18n
        (click)="goToGenerate.emit(); helpMeWriteReviewClicked()"
      >Pomóż mi napisać Opinię
        <svg class="min-h-[24px] h-[24px] min-w-[24px] w-[24px] ml-2" xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink" fill="#ffffff" viewBox="0 0 100 100" xml:space="preserve">
  <g>
    <!-- Large Bold Spark in the center -->
    <polygon points="50,10 57,40 90,47 57,54 50,85 43,54 10,47 43,40" fill="#ffffff"/>

    <!-- Smaller Bold Spark in the top right -->
    <polygon points="80,5 84,18 97,20 84,22 80,35 76,22 63,20 76,18" fill="#ffffff"/>
  </g>
</svg>

      </button>
      <a
        btn-secondary
        btnSize="large"
        noreferrer
        noopener
        class="w-full mb-5"
        i18n
        [href]="questionsService.redirect()?.reviewUrl"
        (click)="leaveAReviewClicked()"
      >Zostaw Opinię w Google
      </a>
    </div>

  `,
  standalone: true,
  imports: [
    ButtonDirective,
    LogoOrTitleComponent
  ]
})
export class ReviewOrGenerateComponent {
  @Output() goToGenerate = new EventEmitter<void>();

  protected readonly questionsService = inject(QuestionsService);
  private readonly mixpanelService = inject(RedirectMixpanelService);

  helpMeWriteReviewClicked() {
    this.mixpanelService.track('Help Me Write Review Clicked', {});
  }

  leaveAReviewClicked() {
    this.mixpanelService.track('Leave A Review Clicked', {
      anyReviewCopied: false
    });
  }
}
