import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SvgIconComponent } from '../../../../ui/svg/svg.component';
import { LinkComponent } from '../../../../ui/link/link.component';
import { StarsComponent } from '../../../../ui/stars/stars.component';
import { LogoOrTitleComponent } from '../../../../ui/logoOrTitle/logoOrTitle.component';
import { Redirect } from '../../../types/redirect.type';
import { show } from '@intercom/messenger-js-sdk';

@Component({
  selector: 'redirect-layout',
  host: {
    class: 'flex flex-col justify-center h-[100dvh] w-full'
  },
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="flex flex-col overflow-hidden w-full h-full max-h-[900px] min-h-[550px] max-w-[450px] mx-auto">
      <div class="flex flex-col items-center w-full pt-3 px-5 h-[calc(100%-52px)]">
        @if (redirect() && !hideLogo()) {
          <client-logo class="my-3" [redirect]="redirect()"></client-logo>
        }
        <ng-content></ng-content>
      </div>

      <footer class="text-center p-4 w-full text-sm">
        powered by <span class="hover:underline cursor-pointer font-bold" (click)="show()">taply</span>
      </footer>
    </div>
  `,
  imports: [
    CommonModule,
    RouterModule,
    SvgIconComponent,
    LinkComponent,
    StarsComponent,
    LogoOrTitleComponent,
  ]
})
export class RedirectLayoutComponent {
  redirect = input<Redirect | undefined>();
  title = input<string>('');
  hideLogo = input<boolean>(false);
  protected readonly show = show;
}
