import { computed, inject, Injectable, LOCALE_ID, signal } from '@angular/core';
import { forkJoin, map, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { PossibleAnswer, Question } from '../types/question.type';
import { Redirect } from '../types/redirect.type';
import { RedirectRestService } from './redirect.rest';
import { GrammaticalForm, isGrammaticalForm, isGrammaticalFormQuestion } from '../types/grammaticalForm.enum';
import { Language } from '../types/language.enum';


@Injectable({
  providedIn: 'root'
})
export class QuestionsService {
  public redirect = signal<Redirect | undefined>(undefined);
  public answers = signal<Partial<Record<string, string[]>>>({});
  public rating = signal<number>(0);
  public applicableQuestions = computed<Question[]>(
    () => this.redirect()?.questions.filter(q => this.isApplicable(q)) ?? []
  );

  public hash = signal('');


  private readonly grammaticalForm = computed(() => {
    const lastAnswer = this.answers()[this.applicableQuestions().slice(-1)[0]?.questionId]?.[0] ?? '';
    return isGrammaticalForm(lastAnswer) ? lastAnswer : GrammaticalForm.Impersonal;
  });


  private readonly reviewLenghts = ['short', 'medium', 'long'];
  private readonly localeId = inject(LOCALE_ID);
  private readonly language = this.localeId === 'pl' ? Language.Pl : Language.En;
  private readonly redirectRestService = inject(RedirectRestService);

  public get anyQuestionAnswered(): boolean {
    return Object.values(this.answers()).some(answer => answer && answer.length > 0);
  }

  public get allQuestionsAnswered(): boolean {
    return this.applicableQuestions()
      .map(question => this.answers()[question.questionId])
      .every(answers => answers && answers.length > 0);
  }

  public get questionsAndAnswers(): string[] {
    return this.applicableQuestions().map((question) => {
      return `${question.question} - ${
        this.answers()[question.questionId]
          ?.map(answerId => question.possibleAnswers.find(a => a.answerId === answerId)?.answer)
          .join(', ')
      }`;
    }) ?? [];
  }

  public getRedirect(hash: string): Observable<Redirect> {
    this.hash.set(hash);

    return this.redirectRestService.getRedirect(hash).pipe(
      tap(redirect => {
        this.redirect.set(Redirect.localize(redirect, this.language));
        this.reset();
      })
    );
  }

  public reset() {
    this.answers.update(() => {
      return (this.redirect()?.questions ?? []).reduce((acc, question) => {
        acc[question.questionId] = [];
        return acc;
      }, {} as Record<string, string[]>);
    });

    this.rating.set(0);
  }

  public toggleAnswer(question: Question, answer: PossibleAnswer) {
    this._toggleAnswer(question, answer);

    if (this.hasDependentQuestions(question)) {
      this.resetFollowingQuestions(question);
    }
  }

  public isAnswerSelected(questionId: string, answerId: string) {
    return this.answers()[questionId]?.includes(answerId);
  }

  public getReviews(): Observable<string[]> {
    return forkJoin(
      this.reviewLenghts.map(length => this.getReview(length))
    ).pipe(
      map(reviews => Array.isArray(reviews) ? reviews : [reviews]),
      map(reviews => reviews.filter(review => !!review))
    );
  }

  public hasDependentQuestions(question: Question) {
    return this.redirect()?.questions.some(({ requiredQuestionAnswer }) =>
      requiredQuestionAnswer?.questionId === question.questionId
    );
  }

  private isApplicable(question: Question) {
    return (
        !isGrammaticalFormQuestion(question)
        || !this.redirect()?.starsEnabled
        || this.rating() > 3
      ) && (
        !question.requiredQuestionAnswer
        || !this.answers()[question.requiredQuestionAnswer.questionId]?.length
        || this.answers()[question.requiredQuestionAnswer.questionId]?.includes(question.requiredQuestionAnswer.answerId)
      );
  }

  private _toggleAnswer(question: Question, answer: PossibleAnswer) {
    const { questionId, questionType } = question;

    if (questionType === 'singleAnswer') {

      this.answers.update(answers => {
        return {
          ...answers,
          [questionId]: [answer.answerId]
        };
      });
    } else {
      if (this.isAnswerSelected(questionId, answer.answerId)) {
        this.answers.update(answers => {
          return {
            ...answers,
            [questionId]: answers[questionId]?.filter(a => a !== answer.answerId)
          };
        });
      } else {
        this.answers.update(answers => {
          return {
            ...answers,
            [questionId]: [...answers[questionId] ?? [], answer.answerId]
          };
        });
      }
    }
  }

  private resetFollowingQuestions(question: Question) {
    const questionIndex = this.redirect()?.questions.findIndex(q => q.questionId === question.questionId);
    if (questionIndex === undefined || questionIndex === -1) return;

    this.answers.update(answers => {
      const followingQuestions = this.redirect()?.questions.slice(questionIndex + 1);
      followingQuestions?.forEach(q => answers[q.questionId] = []);

      return answers;
    });
  }

  private getReview(length: string): Observable<string> {
    return this.redirectRestService.getReview(
      length,
      this.hash(),
      this.redirect()!.questions.map((question) => ({
        questionId: question.questionId,
        values: this.answers()[question.questionId] || []
      })),
      this.grammaticalForm(),
      this.language
    ).pipe(
      catchError(() => of(''))
    );
  }
}


