import { ChangeDetectionStrategy, Component, computed, effect, inject, signal } from '@angular/core';
import { ButtonDirective } from '../../../../../ui/button/button.directive';
import { QuestionsService } from '../../../../services/questions.service';
import { CommonModule, NgClass } from '@angular/common';
import { RedirectMixpanelService } from '../../../../services/redirectMixpanel.service';
import { StarsComponent } from '../../../../../ui/stars/stars.component';
import { Animations } from '../../../../../ui/animations/animatons';
import { EmailReviewFormComponent } from '../../../emailReview/emailReviewForm/emailReviewForm.component';
import { LogoOrTitleComponent } from '../../../../../ui/logoOrTitle/logoOrTitle.component';


@Component({
  selector: 'stars-view',
  templateUrl: 'starsOnlyView.component.html',
  imports: [
    ButtonDirective,
    NgClass,
    CommonModule,
    StarsComponent,
    EmailReviewFormComponent,
    LogoOrTitleComponent
  ],
  standalone: true,
  styles: [
    `:host {
      width: 100%;
    }`
  ],
  host: {
    class: 'flex flex-col justify-center h-full items-center'
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: Animations
})
export class StarsOnlyViewComponent {
  protected ratedNegatively = computed(() => !!this.questionsService.rating() && this.questionsService.rating() < 4);
  protected sent = signal(false);

  protected readonly questionsService = inject(QuestionsService);
  private readonly mixpanelService = inject(RedirectMixpanelService);

  constructor() {
    effect(() => {
      const rating = this.questionsService.rating();
      if (rating > 0) {
        this.mixpanelService.track('Star Rating Given', {
          rating: this.questionsService.rating()
        });
      }

      if (rating >= 4) {
        this.navigate();
      }
    });
  }

  protected navigate() {
    this.mixpanelService.track('Questions Next Clicked', {
      anyQuestionAnswered: this.questionsService.anyQuestionAnswered,
      allQuestionsAnswered: this.questionsService.allQuestionsAnswered
    });

    window.location.href = this.questionsService.redirect()?.reviewUrl ?? '';
  }
}
