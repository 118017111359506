import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, model, OnChanges, Output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RedirectMixpanelService } from '../../../services/redirectMixpanel.service';
import { LoaderComponent } from '../../../../ui/loader/loader.component';

@Component({
  selector: 'reviews',
  templateUrl: 'reviews.component.html',
  imports: [
    LoaderComponent,
    CommonModule
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReviewsComponent {
  @Input() reviews: string[] = [];
  @Output() anyReviewCopied = new EventEmitter<boolean>();

  protected copied = signal('');

  private readonly mixpanelService = inject(RedirectMixpanelService);

  protected copy(index: number, text: string) {
    this.mixpanelService.track('Review Copied', { index });
    this.anyReviewCopied.emit(true);

    navigator.clipboard.writeText(text).then(() => {
      this.copied.set(text);
    }).catch(err => {
      console.error('Error while copying text: ', err);
    });
  }
}
