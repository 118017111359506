<div class="flex flex-col overflow-y-scroll">
  <div class="w-full flex flex-col items-center">
    <h1 class="font-bold text-2xl" i18n>Skopiuj jedną z propozycji</h1>
    <p class="text-black text-center font-light mb-6 text-sm" i18n>Potraktuj je jako inspiracje, jeśli możesz - <strong>dodaj coś od Siebie!</strong></p>
  </div>

  <reviews class="flex flex-col overflow-y-scroll px-[6px] pt-2" [reviews]="reviews()" (anyReviewCopied)="anyReviewCopied.set($event)"></reviews>
</div>

<div class="flex">
  <button
    btn-secondary
    class="w-full mr-2 flex-shrink"
    btnSize="large"
    (click)="refreshReviews()"
    i18n
  >
    <span class="material-symbols-outlined mr-1">refresh</span>
    Inne
  </button>
  <a
    btn-primary
    btnSize="large"
    class="w-full flex-grow"
    noreferrer
    noopener
    [href]="questionsService.redirect()?.reviewUrl"
    (click)="leaveAReviewClicked()"
    i18n
  >Zostaw Opinię w Google</a>
</div>
