import '@stripe/stripe-js';
import * as Sentry from "@sentry/angular";
import { APP_INITIALIZER, ApplicationConfig, ErrorHandler } from '@angular/core';
import { Router, provideRouter, withComponentInputBinding } from '@angular/router';

import { routes } from './app.routes';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { authInterceptorFunction } from './auth/auth.interceptor';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideToastr } from 'ngx-toastr';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { AppEnvironment } from '../environments/environment';

Sentry.init({
  dsn: "https://aaf3c381fb40220df3221ad3384665e7@o4508371919110144.ingest.de.sentry.io/4508371920879696",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["https://app.taply.help", new RegExp(`^${AppEnvironment.apiUrl}`)],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withComponentInputBinding()),
    provideHttpClient(withInterceptors([authInterceptorFunction]), withFetch()),
    provideAnimations(),
    provideToastr(),
    provideCharts(withDefaultRegisterables()),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    }
]
};
