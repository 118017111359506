import { ChangeDetectionStrategy, Component, inject, model, signal } from '@angular/core';
import { ButtonDirective } from '../../../../ui/button/button.directive';
import { ReviewsComponent } from '../../googleReview/reviews/reviews.component';
import { StarsComponent } from '../../../../ui/stars/stars.component';
import { LabelComponent } from '../../../../ui/label/label.component';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from '../../../../ui/loader/loader.component';
import { QuestionsService } from '../../../services/questions.service';
import { RedirectMixpanelService } from '../../../services/redirectMixpanel.service';
import { OpinionService } from '../../../services/opinion.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'email-review-form',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ButtonDirective,
    ReviewsComponent,
    StarsComponent,
    LabelComponent,
    ReactiveFormsModule,
    CommonModule,
    LoaderComponent
  ],
  templateUrl: 'emailReviewForm.component.html',
  host: {
    class: 'flex flex-col h-full overflow-x-hidden justify-between'
  }
})
export class EmailReviewFormComponent {
  public sent = model(false);
  protected submitted = signal(false);
  protected loading = signal(false);

  protected readonly questionsService = inject(QuestionsService);

  private readonly mixpanelService = inject(RedirectMixpanelService);
  private readonly opinionService = inject(OpinionService);

  protected form = inject(FormBuilder).group({
    email: ['', Validators.email],
    opinion: ['', Validators.required]
  });

  public leaveAReviewClicked() {
    this.mixpanelService.track('Email Opinion Left', {
      email: this.form.get('email')?.value,
      opinion: this.form.get('opinion')?.value,
      rating: this.questionsService.rating(),
      questionsAndAnswers: this.questionsService.questionsAndAnswers
    });

    this.submitted.set(true);
    if (this.form.valid) {
      this.loading.set(true);

      this.opinionService
      .sendOpinion(
        this.questionsService.hash(),
        this.questionsService.rating(),
        this.form.get('opinion')?.value ?? ''
      )
      .pipe(
        finalize(() => {
          this.loading.set(false);
          this.sent.set(true);
        })
      )
      .subscribe();
    }
  }
}
