import { animate, state, style, transition, trigger } from '@angular/animations';

const transitions = [
  transition('front => back', [
    animate('0.6s cubic-bezier(0.895, 0.030, 0.685, 0.220)')
  ]),
  transition('back => front', [
    animate('0.6s cubic-bezier(0.895, 0.030, 0.685, 0.220)')
  ]),
  transition('back => frontSecondary', [
    animate('0.3s cubic-bezier(0.165, 0.840, 0.440, 1.000)')
  ]),
];

export const Animations = [
  trigger('stars', [
    state('front', style({
      transform: 'translateY(40vh) scale(2)',
      gap: '0.5rem'
    })),
    state('back', style({
      transform: 'translateY(0) scale(1)',
      gap: '0rem'
    })),
    ...transitions
  ]),
  trigger('text', [
    state('back', style({
      transform: 'translateY(-1000px)',
      height: 0,
    })),
    state('front', style({
      transform: 'translateY(0)',
    })),
    ...transitions
  ]),
  trigger('moveIn', [
    state('back', style({
      transform: 'translateY(800px)',
      opacity: 0,
    })),
    state('front', style({
      transform: 'translateY(0)',
      opacity: 1
    })),
    state('frontSecondary', style({
      transform: 'translateY(0)',
      opacity: 1
    })),
    ...transitions
  ]),
  trigger('appear', [
    state('back', style({
      height: 0,
    })),
    state('front', style({
      height: '*'
    })),
    ...transitions
  ])
];
