import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { SvgIconComponent } from '../../../../ui/svg/svg.component';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { filter, map, switchMap } from 'rxjs';
import { QuestionsService } from '../../../services/questions.service';
import { AsyncPipe, JsonPipe, NgForOf, NgIf } from '@angular/common';
import { ButtonDirective } from '../../../../ui/button/button.directive';
import { RedirectLayoutComponent } from '../redirectLayout/redirectLayout.component';
import { catchError, tap } from 'rxjs/operators';
import { RedirectMixpanelService } from '../../../services/redirectMixpanel.service';
import { StarsComponent } from '../../../../ui/stars/stars.component';

import { CardIdRegex } from '../../../../utils/cardURlRegex';
import Intercom from '@intercom/messenger-js-sdk';
import { RedirectRestService } from '../../../services/redirect.rest';

@Component({
  templateUrl: 'redirectContainer.component.html',
  imports: [
    SvgIconComponent,
    NgIf,
    AsyncPipe,
    JsonPipe,
    ButtonDirective,
    NgForOf,
    RouterOutlet,
    RedirectLayoutComponent,
    StarsComponent
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RedirectMixpanelService]
})
export class RedirectContainerComponent implements OnInit {
  protected readonly questionsService = inject(QuestionsService);
  protected readonly router = inject(Router);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly mixpanelService = inject(RedirectMixpanelService);
  private readonly redirectRestService = inject(RedirectRestService);

  public ngOnInit() {
    Intercom({
      app_id: 'h484cfk4',
      hide_default_launcher: true
    });

    this.activatedRoute.params.pipe(
      map(params => params['hash']),
      filter(hash => hash !== undefined),
      switchMap(hash => this.questionsService.getRedirect(hash)),
      tap(client => {
        if (!client.starsEnabled && !client.reviewAssistantEnabled) {
          document.location.href = client.reviewUrl;
        }

        this.mixpanelService.redirect.set({ ...client, hash: this.questionsService.hash() });

        this.mixpanelService.track('Redirect Opened', {});
        this.redirectRestService.logRedirectOpened("hardcodedDeviceId", this.questionsService.hash()).subscribe();
      }),
      catchError(() => {
        const matches = this.router.url.replace('/redirect/', '').match(CardIdRegex);
        const cardId = matches?.[1] || matches?.[2];

        if (cardId) {
          return this.router.navigate(['/activate'], { queryParams: { cardId } });
        } else {
          return this.router.navigate(['/404']);
        }
      })
    ).subscribe();
  }
}
