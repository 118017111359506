import { ChangeDetectionStrategy, Component, inject, OnInit, signal } from '@angular/core';
import { ButtonDirective } from '../../../../ui/button/button.directive';
import { ReviewsComponent } from '../reviews/reviews.component';
import { QuestionsService } from '../../../services/questions.service';
import { RedirectMixpanelService } from '../../../services/redirectMixpanel.service';
import { StarsComponent } from '../../../../ui/stars/stars.component';
import { NgIf } from '@angular/common';
import { LogoOrTitleComponent } from '../../../../ui/logoOrTitle/logoOrTitle.component';
import { StarRatingComponent } from '../../../../ui/star/star.component';

@Component({
  selector: 'review-view',
  templateUrl: './reviewView.component.html',
  imports: [
    ButtonDirective,
    ReviewsComponent,
    StarsComponent,
    NgIf,
    LogoOrTitleComponent,
    StarRatingComponent
  ],
  standalone: true,
  styles: [
    `:host {
      width: 100%;
    }`
  ],
  host: {
    class: 'flex flex-col h-[100%] overflow-x-hidden justify-between'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReviewViewComponent implements OnInit {
  protected readonly questionsService = inject(QuestionsService);
  protected reviews = signal<string[]>([]);
  protected anyReviewCopied = signal(false);
  protected refreshCount = 0;

  private readonly mixpanelService = inject(RedirectMixpanelService);

  public ngOnInit() {
    this.getReviews();
  }

  public refreshReviews(): void {
    if (!this.reviews().length) return;

    this.mixpanelService.track('Refresh Reviews Clicked', {
      refreshCount: this.refreshCount++
    });

    this.reviews.set([]);
    this.getReviews();
  }

  protected leaveAReviewClicked() {
    this.mixpanelService.track('Leave A Review Clicked', {
      anyReviewCopied: this.anyReviewCopied()
    });
  }

  private getReviews() {
    this.questionsService.getReviews().subscribe(reviews => {
      this.reviews.set(reviews);
    });
  }
}

