import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { QuestionsService } from '../../../services/questions.service';
import { CommonModule } from '@angular/common';
import { StarsOnlyViewComponent } from './starsOnlyView/starsOnlyView.component';
import { ReviewOrGenerateComponent } from '../reviewOrGenerate/reviewOrGenerate.component';
import { QuestionsViewNewComponent } from './questionsViewNew/questionsViewNew.component';

@Component({
  selector: 'redirect-landing',
  standalone: true,
  imports: [
    CommonModule,
    StarsOnlyViewComponent,
    QuestionsViewNewComponent,
    ReviewOrGenerateComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'w-full h-full'
  },
  template: `
    @if (questionsService.redirect()?.reviewAssistantEnabled) {
      @if (!generateClicked && !questionsService.redirect()?.starsEnabled) {
        <review-or-generate (goToGenerate)="generateClicked = true"></review-or-generate>
      } @else {
        <questions-view-new></questions-view-new>
      }
    } @else {
      <stars-view></stars-view>
    }
  `
})
export class RedirectViewComponent {
  protected readonly questionsService = inject(QuestionsService);
  protected generateClicked = false;
}
