@if (questionsService.redirect()) {
  <div class="flex flex-col items-center justify-center">
    @if (questionsService.redirect()?.starsEnabled) {
      <div class="mb-8 flex flex-col items-center">
        <div class="flex items-center justify-center relative">
          <h1
            [@text]="rated() ? 'back' : 'front'"
            class="font-bold text-2xl mb-3 absolute top-[calc(40vh-54px)] whitespace-nowrap"
            i18n
          >Oceń nas!</h1>
          <app-star-rating
            class="absolute top-0 -mt-1"
            [@stars]="rated() ? 'back' : 'front'"
            [(rating)]="questionsService.rating"
            [disabled]="rated()"
          ></app-star-rating>
        </div>
      </div>
    }

    @if (questionsService.redirect()?.starsEnabled) {
    }

    @let question = currentQuestion();
    <div
      [@moveIn]="rated() ? 'front' : 'back'"
      [@appear]="rated() ? 'front' : 'back'"
      class="flex flex-col items-center mb-4"
    >
      <label class="text-center text-lg mb-3">{{ question.question }}</label>

      <div class="flex flex-col items-center">

        @for (answer of question.possibleAnswers; track answer) {
          <span
            class="whitespace-nowrap inline-flex w-fit items-center px-3 py-2 me-2 mb-3 text-sm font-medium text-black rounded-[60px] outline outline-1 outline-black cursor-pointer transition-colors duration-200 ease-in-out"
            (click)="toggleAnswer(question, answer)"
            [ngClass]="
              questionsService.answers()[question.questionId]?.includes(answer.answerId)
                ? 'bg-black text-white'
                : 'lg:hover:bg-gray-200'
            "
          >
            {{ answer.answer }}
          </span>
        }

      </div>
    </div>
  </div>
}
