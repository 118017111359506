<div *ngIf="!sent()" class="mb-7 flex flex-col items-center relative">
  <div class="flex items-center justify-center relative">
    <app-star-rating
      class="-mt-1 absolute top-0"
      [disabled]="true"
      [rating]="questionsService.rating()"
    ></app-star-rating>
  </div>
</div>

<email-review-form [(sent)]="sent"></email-review-form>
