import { Question } from './question.type';

export enum GrammaticalForm {
  Feminine = 'feminine',
  Masculine = 'masculine',
  Impersonal = 'impersonal'
}

export const isGrammaticalForm = (value: string): value is GrammaticalForm => Object.values(GrammaticalForm).includes(value as GrammaticalForm);
export const isGrammaticalFormQuestion = (question: Question): boolean => question.possibleAnswers.some(answer => isGrammaticalForm(answer.answerId));
