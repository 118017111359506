import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SvgIconComponent } from '../../../ui/svg/svg.component';
import { CommonModule } from '@angular/common';
import { ButtonDirective } from '../../../ui/button/button.directive';
import { RouterOutlet } from '@angular/router';
import { RedirectLayoutComponent } from '../redirect/redirectLayout/redirectLayout.component';
import { LinkComponent } from '../../../ui/link/link.component';
import { show } from '@intercom/messenger-js-sdk';

@Component({
  selector: 'not-found',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    SvgIconComponent,
    ButtonDirective,
    RouterOutlet,
    CommonModule,
    RedirectLayoutComponent,
    LinkComponent
  ],
  template: `
    <redirect-layout [title]="'Ups! Nie znaleziono strony.'">
      <div class="flex flex-col items-center justify-center h-full">
        <p class="text-center" i18n>
          Jeśli przekierowała Cię tutaj karta taply, <span class="hover:underline cursor-pointer font-bold" (click)="show()">napisz do nas</span>.
        </p>
      </div>
    </redirect-layout>
  `
})
export class NotFoundComponent {
  protected readonly show = show;
}
