import { Component, input, Input, model, OnChanges } from '@angular/core';
import { NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'app-star-rating',
  templateUrl: 'star.component.html',
  styleUrls: ['star.component.scss'],
  standalone: true,
  imports: [
    NgFor,
    NgIf
  ]
})
export class StarRatingComponent implements OnChanges {
  rating = model<number>(0);
  disabled = input<boolean>(false);
  starWidth = input<number>(24);

  starFills: number[] = [];

  ngOnChanges(): void {
    this.calculateStarFills();
  }

  onClick(rating: number): void {
    if (this.disabled()) {
      return;
    }

    this.rating.set(rating);
    this.calculateStarFills();
  }

  private calculateStarFills(): void {
    this.starFills = Array.from({ length: 5 }, (_, i) => {
      const starIndex = i + 1; // 1-based index for stars
      if (this.rating() >= starIndex) {
        return 100; // Fully filled
      } else if (this.rating() > i && this.rating() < starIndex) {
        return (this.rating() - i) * 100; // Partially filled
      } else {
        return 0; // Empty
      }
    });
  }
}
