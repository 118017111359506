import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { ButtonDirective } from '../../../../ui/button/button.directive';
import { ReviewsComponent } from '../../googleReview/reviews/reviews.component';
import { QuestionsService } from '../../../services/questions.service';
import { StarsComponent } from '../../../../ui/stars/stars.component';
import { LabelComponent } from '../../../../ui/label/label.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from '../../../../ui/loader/loader.component';
import { EmailReviewFormComponent } from '../emailReviewForm/emailReviewForm.component';
import { LogoOrTitleComponent } from '../../../../ui/logoOrTitle/logoOrTitle.component';
import { StarRatingComponent } from '../../../../ui/star/star.component';

@Component({
  selector: 'email-review-view',
  templateUrl: 'emailReviewView.component.html',
  imports: [
    ButtonDirective,
    ReviewsComponent,
    StarsComponent,
    LabelComponent,
    ReactiveFormsModule,
    CommonModule,
    LoaderComponent,
    EmailReviewFormComponent,
    LogoOrTitleComponent,
    StarRatingComponent
  ],
  standalone: true,
  styles: [
    `:host {
      width: 100%;
    }`
  ],
  host: {
    class: 'flex flex-col h-full'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailReviewViewComponent {
  protected sent = signal(false);

  protected readonly questionsService = inject(QuestionsService);
}

