import { ChangeDetectionStrategy, Component, computed, effect, inject, OnInit, signal } from '@angular/core';
import { ButtonDirective } from '../../../../../ui/button/button.directive';
import { QuestionsService } from '../../../../services/questions.service';
import { CommonModule, NgClass } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { RedirectMixpanelService } from '../../../../services/redirectMixpanel.service';
import { StarsComponent } from '../../../../../ui/stars/stars.component';
import { PossibleAnswer, Question } from '../../../../types/question.type';
import { Animations } from '../../../../../ui/animations/animatons';
import { LogoOrTitleComponent } from '../../../../../ui/logoOrTitle/logoOrTitle.component';
import { StarRatingComponent } from '../../../../../ui/star/star.component';


@Component({
  selector: 'questions-view-new',
  templateUrl: 'questionsViewNew.component.html',
  imports: [
    ButtonDirective,
    NgClass,
    CommonModule,
    StarsComponent,
    LogoOrTitleComponent,
    StarRatingComponent
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    style: 'width: 100%;'
  },
  animations: Animations
})
export class QuestionsViewNewComponent implements OnInit {
  protected readonly router = inject(Router);
  protected readonly route = inject(ActivatedRoute);
  protected questionsService = inject(QuestionsService);

  protected rated = computed(() => !!this.questionsService.rating() || !this.questionsService.redirect()?.starsEnabled);
  protected currentQuestion = signal<Question>(this.questionsService.applicableQuestions()[0]);
  protected indexOfCurrentQuestion = computed(() => this.questionsService.applicableQuestions().indexOf(this.currentQuestion()));
  protected previousQuestion = computed<Question | undefined>(() => this.questionsService.applicableQuestions()[this.indexOfCurrentQuestion() - 1]);
  protected nextQuestion = computed<Question | undefined>(() => this.questionsService.applicableQuestions()[this.indexOfCurrentQuestion() + 1]);

  private readonly mixpanelService = inject(RedirectMixpanelService);

  constructor() {
    effect(() => {
      const answeredId = this.previousQuestion()?.questionId;
      if (answeredId === undefined) return;

      this.mixpanelService.track('Question Answered', {
        question: this.questionsService.redirect()!.questions.find(q => q.questionId === answeredId)!.question,
        questionType: this.questionsService.redirect()!.questions.find(q => q.questionId === answeredId)!.questionType
      });
    });

    effect(() => {
      if (this.questionsService.rating()) {
        this.mixpanelService.track('Star Rating Given', {
          rating: this.questionsService.rating()
        });
      }
    });
  }

  ngOnInit(): void {
    if (this.questionsService.redirect()) {
      this.questionsService.reset();
    }
  }

  protected toggleAnswer(question: Question, answer: PossibleAnswer) {
    this.questionsService.toggleAnswer(question, answer);
    setTimeout(() => this.next(), 500);
  }

  protected next() {
    const next = this.nextQuestion();
    if (!next) return this.navigate();

    this.currentQuestion.set(next);
  }

  private navigate() {
    this.mixpanelService.track('Questions Next Clicked', {
      anyQuestionAnswered: this.questionsService.anyQuestionAnswered,
      allQuestionsAnswered: this.questionsService.allQuestionsAnswered,
      questionsAndAnswers: this.questionsService.questionsAndAnswers
    });

    this.router.navigate(
      [!this.questionsService.redirect()?.starsEnabled || this.questionsService.rating() > 3 ? 'review' : 'opinion'],
      { relativeTo: this.route }
    );
  }

}
