<div class="star-rating">
  <div
    [class.cursor-pointer]="!disabled()"
    class="star-container text-yellow-300"
    *ngFor="let fill of starFills; let i = index"
    (click)="onClick(i + 1)"
    [style.width.px]="starWidth()"
    [style.position]="'relative'"
  >
    <!-- Empty Star -->
    <svg xmlns="http://www.w3.org/2000/svg"
         width="24"
         height="24"
         viewBox="0 0 24 24"
         fill="none"
         stroke="currentColor"
         stroke-width="2"
         stroke-linecap="round"
         stroke-linejoin="round"
         class="feather feather-star star-empty">
      <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"/>
    </svg>

    <!-- Filled Star (Mask with dynamic fill %) -->
    <svg xmlns="http://www.w3.org/2000/svg"
         width="24"
         height="24"
         viewBox="0 0 24 24"
         fill="currentColor"
         [style.clip-path]="'inset(0 ' + (100 - fill) + '% 0 0)'"
         class="feather feather-star star-filled">
      <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"/>
    </svg>
  </div>
</div>
